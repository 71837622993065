import espresso from './espresso.jpg'
import windowsill from './windowsillsm.jpg'
import machine from './machine.jpg'
import business from './business.jpg'
import contact from './contact.jpg'
import bg from './bg.png'

const Photos = {
    business,
    contact,
    espresso,
    windowsill,
    machine,
    bg,
}

export default Photos