import React, {useState, createRef, useEffect} from 'react'
import { useScrollPosition } from '../../hooks/useScrollPosition'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { addLineItem, handleLoadingCheckout } from '../../store'
import './styles.scss'
import Icons from '../../assets/icons'
import priceFormatter from '../../helpers/priceFormatter'

const isVisible = (prodRef) => {
    return window.innerHeight > window.innerWidth
    ?
        (prodRef.current.offsetTop + (prodRef.current.clientHeight * 0.5)) < (window.scrollY + window.innerHeight)
    :
        (prodRef.current.offsetTop + (prodRef.current.clientHeight * 0.3)) < (window.scrollY + window.innerHeight)
}

const isActive = (prodRef) => {
    return window.innerHeight > window.innerWidth
    ?
        prodRef.current.offsetTop + (prodRef.current.clientHeight * 0.3) > window.scrollY
        && (prodRef.current.offsetTop + (prodRef.current.clientHeight * 0.8)) < (window.scrollY + window.innerHeight)
    :
        prodRef.current.offsetTop > window.scrollY
        && (prodRef.current.offsetTop + prodRef.current.clientHeight) < (window.scrollY + window.innerHeight)
}

const getImageMap = (coffeeVariants) => {
    const coffeeImages = []

    coffeeVariants.forEach(variant => {
        const imageIndex = coffeeImages.findIndex(image => image?.src === variant?.image?.src)

        if (imageIndex < 0) {
            coffeeImages.push({
                src: variant?.image?.src,
                variants: [variant?.id]
            }) 
        } else {
            coffeeImages[imageIndex].variants.push(variant.id)
        }
    })

    return coffeeImages
}

const Product = ({ coffee }) => {
    const prodRef = createRef(null)

    const dispatch = useDispatch()

    const [activeVariant, setActiveVariant] = useState(coffee?.variants ? coffee.variants[0] : {})
    const [quantity, setQuantity] = useState(1)

    const [variant, setVariant] = useState('hidden')
    const [active, setActive] = useState(false)

    const checkoutId = useSelector(state => state.checkoutId)

    useEffect(() => {
        const visible = isVisible(prodRef)
        const activeProduct = isActive(prodRef)

        if (visible) setVariant('visible')

        const doubleHeight = prodRef?.current?.clientHeight ? prodRef?.current?.clientHeight * 2 : window.innerHeight

        if (window.innerHeight < doubleHeight) setActive(activeProduct)
    }, [prodRef])

    useScrollPosition(() => {
        const visible = isVisible(prodRef)
        const activeProduct = isActive(prodRef)
        
        visible ? setVariant('visible') : setVariant('hidden')

        const doubleHeight = prodRef?.current?.clientHeight ? prodRef?.current?.clientHeight * 2 : window.innerHeight
        if (window.innerHeight < doubleHeight) setActive(activeProduct)
    })

    const handleChange = (e) => {
        if (!isNaN(e.target.value)) {
            if (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 100) {
                setQuantity(parseInt(e.target.value))
            } else if (e.target.value === "") {
                setQuantity(0)
            }
        } 
    }

    const handleActiveVariantChange = (name, val) => {
        const otherOptions = activeVariant.selectedOptions.filter(option => option.name !== name)

        const variantToChangeTo = coffee.variants.find(vari => {
            const matchingNameAndVal = vari.selectedOptions.find(option => (
                option.name === name 
                && option.value === val
            ))

            const otherOptionsMatched = otherOptions.map(op => {
                return vari.selectedOptions.find(option => (
                    option.name === op.name 
                    && option.value === op.value
                ))
            }).find(o => o)

            return matchingNameAndVal && otherOptionsMatched
        })

        if (!variantToChangeTo) { 
            setActiveVariant(coffee.variants.find(vari => vari.selectedOptions.find(option => (
                option.name === name 
                && option.value === val
            ))))
        } else {
            setActiveVariant(variantToChangeTo)
        }
    }

    const variants = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 1
            }
        },
        visible: {
            opacity: 1,
            transition: 0.5
        }
    }

    return (
        <div style={{ background: active ? 'rgba(186, 155, 103, 0.05)' : 'rgba(186, 155, 103, 0)'}} className='product-container' ref={prodRef}>

            <motion.div initial='hidden' animate={variant} variants={variants} className='product-image-container'>
                {/* <img className='product-image-bg' src={coffee.bg} alt='splash' /> */}
                {
                    coffee?.variants && getImageMap(coffee?.variants).map((image, i) => {
                        return (
                            <img key={coffee.name + i} className={`product-image ${image.variants.includes(activeVariant.id) ? 'active-image' : ''} ${coffee.name.includes("NESPRESSO") ? 'nespresso' : ''}`} src={image.src} alt={coffee.name} />
                        )
                    })
                }
                
            </motion.div>

            <motion.div initial='hidden' animate={variant} variants={variants} className='product-description-container'> 

                <h3 className='product-name'>
                    {coffee?.name?.toUpperCase()}
                </h3>
                <p className='product-price'>${priceFormatter(activeVariant?.price?.amount)}</p>
                <p className='product-roast'>
                    {coffee.roast}
                </p>
                <p className='product-description'>
                    {coffee.description}
                </p>

                {coffee?.options?.find(o => o.name === "Size")
                && coffee?.options?.find(o => o.name === "Size")?.values?.length > 1
                && <h4 className="options-header">Size</h4>}

                <div className='product-selector-container'>
                    {
                        coffee?.options?.find(o => o.name === "Size") && coffee?.options?.find(o => o.name === "Size").values.map((size) => {
                            return (
                                
                                <p key={coffee.name + size.value} onClick={() => handleActiveVariantChange("Size", size.value)} className={`size-selector${activeVariant.selectedOptions.find(o => o.value ===  size.value) ? "-active" : "-inactive"}`}>{size.value}</p>
                                
                            )
                        })
                    }
                    <div className='quant-selector-container' style={ { marginLeft: (coffee?.options?.find(o => o.name === "Size") && coffee?.options?.find(o => o.name === "Size").values) ? '2rem' : '' } }>
                        <p onClick={(e) => { 
                            e.preventDefault()
                            if (quantity > 1) setQuantity(quantity - 1)}
                        } className="quant-selector">-</p>
                        <input className="quant-input" type="text" onChange={handleChange} value={quantity} />
                        <p onClick={(e) => { 
                            e.preventDefault()
                            setQuantity(quantity + 1) 
                        }} className="quant-selector">+</p>
                    </div>
                </div>

                {coffee?.options?.find(o => o?.values?.find(op => op?.value === "Whole")) 
                && coffee?.options?.find(o => o?.values?.find(op => op?.value === "Drip")) 
                && <h4 className="options-header">Whole/Ground</h4>}

                {
                    <div className='grind-selector-container'>
                        {
                            coffee?.options?.find(o => o.name === "Style") && coffee?.options?.find(o => o.name === "Style").values.map((style, i) => {
                                const isActive = activeVariant.selectedOptions.find(o => o.value ===  style.value)
                                return (
                                    <div key={coffee.name + style.value} onClick={() => handleActiveVariantChange("Style", style.value)} className={`grind-selector-option ${isActive && 'active-grind'}`}>
                                        <p className="grind-selector">
                                            <img src={Icons[style.value]} alt={style.value} className='grind-icon'/>
                                        </p>
                                        <p className={`${isActive ? "grind-selector-name" : "grind-selector-inactive-name"}`}>
                                            {style.value}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                

                <p onClick={(e) => { 
                    e.preventDefault()
                    if (checkoutId && activeVariant?.id) {
                        const lineItemsToAdd = [
                            {
                                variantId: activeVariant?.id,
                                quantity,
                                customAttributes: []
                            }
                        ]
                        dispatch(handleLoadingCheckout())
                        dispatch(addLineItem({ checkoutId, lineItem: lineItemsToAdd }))
                    }
                }} className="cart-add">ADD TO CART</p>
            </motion.div>
        </div>
    )
}

export default Product