import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

import './styles.scss'
import { useEffect } from 'react';

const ContactForm = () => {
    const form = useRef();
    const [contactFormDetails, setContactFormDetails] = useState({
        first_name: '',
        last_name: '',
        from_email: '',
        contact_phone: '',
        message: '',
    })

    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)

    const submitButtonWaitingStyle = {
        text: "send",
        background: "rgb(31,56,84)"
    }

    const submitButtonSubmittingStyle = {
        text: "sending...",
        background: "rgb(31,56,84)"
    }

    const submitButtonSuccessStyle = {
        text: "sent!",
        background: "rgb(0,153,0)"
    }

    let submitButtonStyle;

    if (submitting) {
        submitButtonStyle = submitButtonSubmittingStyle;
    } else {
        submitButtonStyle = success ? submitButtonSuccessStyle : submitButtonWaitingStyle;
    }

    const {
        first_name,
        last_name,
        from_email,
        contact_phone,
        message,
    } = contactFormDetails

    useEffect(() => {
        if (!submitting && success) {
            setTimeout(() => {
                setSuccess(false)
            }, 5000)
        }
    }, [submitting, success])

    const handleFormInput = (fieldName, value) => {
        setContactFormDetails({
            ...contactFormDetails,
            [fieldName]: value,
        })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if ((from_email || contact_phone) && message && !submitting) {
            setSubmitting(true);
            try {
                await emailjs.sendForm("Vibe Coffee Contact", "template_gq7zcsp", form.current, "bA0CFDFVthWyNKJdW")
                setSubmitting(false)
                setSuccess(true)
            } catch (e) {
                setSubmitting(false)
                setSuccess(false)
            }
        }
    }

    return (
        <form className='contact-form-container' ref={form}>
            <div className='contact-form-input-row'>
                <div className='contact-form-input-group'>
                    <label>first name</label>
                    <input className="contact-form-input" name="first_name" type="text" onChange={(e) => handleFormInput('first_name', e.target.value)} value={first_name}/>
                </div>
                <div className='contact-form-input-group'>
                    <label>last name</label>
                    <input className="contact-form-input" name="last_name" type="text" onChange={(e) => handleFormInput('last_name', e.target.value)} value={last_name}/>
                </div>
            </div>
            <div className='contact-form-input-row'>
                <div className='contact-form-input-group'>
                    <label>email</label>
                    <input className="contact-form-input" name="from_email" type="text" onChange={(e) => handleFormInput('from_email', e.target.value)} value={from_email}/>
                </div>
                <div className='contact-form-input-group'>
                    <label>contact phone</label>
                    <input className="contact-form-input" name="contact_phone" type="text" onChange={(e) => handleFormInput('contact_phone', e.target.value)} value={contact_phone}/>
                </div>
            </div>
            <div className='contact-form-input-row'>
                <div className='contact-form-input-group' style={{ width: '100%' }}>
                    <label>vibe coffee for business inquiry</label>
                    <textarea className="contact-form-input-area" name="message" onChange={(e) => handleFormInput('message', e.target.value)} value={message}/>
                </div>
            </div>
            <div className='contact-form-input-row'>
                <div className='contact-form-submit-button' onClick={handleFormSubmit} style={{ width: '100%', background: submitButtonStyle.background }}>
                    <div>{submitButtonStyle.text}</div>
                </div>
            </div>
        </form>
    )
}

export default ContactForm