import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import priceFormatter from '../../helpers/priceFormatter'
import { updateLineItem, removeLineItem, handleLoadingCheckout } from '../../store'
import './styles.scss'

const CartItem = ({ item, last }) => {
    const dispatch = useDispatch()
    const checkoutId = useSelector(state => state.checkoutId)

    const handleQuantitySelect = (e) => {
        e.preventDefault()
        dispatch(handleLoadingCheckout())
        dispatch(updateLineItem({checkoutId, lineItem: [{ id: item.id, quantity: parseInt(e.target.value) }]}))
    }

    const handleRemoveItem = (e) => {
        e.preventDefault()
        dispatch(handleLoadingCheckout())
        dispatch(removeLineItem({checkoutId, lineItem: [ item.id ]}))
    }

    const options = [...Array(99).keys()].map(num => ({
        value: num + 1,
        label: num + 1
    }))

    return (
        <div className={`cart-item ${last && 'no-border'}`}>
            <div style={{width: '80px', paddingRight: '10px', borderRight: '1px solid rgba(0,0,0,0.2)'}}>
                <img src={item?.variant?.image?.src} alt={"cart-preview-img"} style={{height: '80px', maxWidth: '80px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
            </div>
            <div style={{width: '100%', maxWidth: '8.5em', marginLeft: '10px'}}>
                <h3>{item?.title?.toUpperCase()}</h3>
                {item?.variant?.selectedOptions && item?.variant?.selectedOptions.map(option => {
                    return (
                        <p style={{fontWeight: '400'}} key={item?.id + option?.name}>{option?.value}</p>
                    )
                })}
            </div>

            <select className='cart-quant-select' style={{margin:'5px', marginRight: '5px'}} value={item?.quantity && item?.quantity} onChange={handleQuantitySelect}>
                {options.map(option => <option key={option.label} value={option.value}>{option.label}</option>)}
            </select>

            <h3 style={{fontSize: '1.5em', width: '3em', textAlign: 'center'}}>{`$${item?.quantity * priceFormatter(item?.variant?.price?.amount)}`}</h3>

            <span onClick={handleRemoveItem}><i style={{color: 'red'}} className="fas fa-minus-circle"></i></span>
        </div>
    )
}

export default CartItem