import { createSlice, configureStore, createAsyncThunk } from '@reduxjs/toolkit'
import shopifyClient from '../shopifyClient'
import Cookies from 'js-cookie'

const parseProduct = ({id, availableForSale, description, title, productType, options, variants, createdAt}) => {
  return {
    id,
    availableForSale,
    description,
    name: title,
    roast: productType,
    options,
    variants,
    createdAt
  }
}

export const initCheckout = createAsyncThunk(
  'initCheckout',
  async () => {
    const createAndSetCheckout = async () => {
      const newCheckout = await shopifyClient.checkout.create()
      if (newCheckout?.id) {
        Cookies.set("vibeCheckout", newCheckout?.id, { expires: 7 })
        return {
          checkoutId: newCheckout.id,
          checkout: {...newCheckout}
        }
      }
      return null
    }
    
    
    const fetchCheckout = async (checkoutId) => {
      const existingCheckout = await shopifyClient.checkout.fetch(checkoutId)
    
      if (existingCheckout && !existingCheckout?.completedAt) return {
        checkoutId,
        checkout: {...existingCheckout}
      }
      else {
        const newCheckout = createAndSetCheckout()
        return newCheckout
      }
    }

    const existingCheckoutId = Cookies.get("vibeCheckout")

    if (existingCheckoutId) return fetchCheckout(existingCheckoutId)
    
    return createAndSetCheckout()
  }
)

export const fetchCollections = createAsyncThunk(
  'fetchCollections',
  async () => {
    const collectionData = await shopifyClient.collection.fetchAllWithProducts()
    const fetchedCollections = collectionData?.filter(collection => collection?.products)

    if (fetchedCollections.length > 0) {
      const parsedCollections = fetchedCollections?.map(collection => ({
          id: collection?.id,
          title: collection?.title,
          products: collection?.products.filter(product => product?.id).map(p => parseProduct(p)).sort((a, b) => {
            const dateA = new Date(a.createdAt)
            const dateB = new Date(b.createdAt)

            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
            return 0;
          })
      }))

      return [...parsedCollections]
    }
    return null
  }
)

export const addLineItem = createAsyncThunk(
  'addLineItem',
  async ({ checkoutId, lineItem }, thunkAPI) => {
    const updatedCheckout = await shopifyClient.checkout.addLineItems(checkoutId, lineItem)

    if (updatedCheckout) return {...updatedCheckout}
    return null
  }
)

export const updateLineItem = createAsyncThunk(
  'updateLineItem',
  async ({ checkoutId, lineItem }, thunkAPI) => {
    const updatedCheckout = await shopifyClient.checkout.updateLineItems(checkoutId, lineItem)

    if (updatedCheckout) return {...updatedCheckout}
    return null
  }
)

export const removeLineItem = createAsyncThunk(
  'removeLineItem',
  async ({ checkoutId, lineItem }, thunkAPI) => {
    const updatedCheckout = await shopifyClient.checkout.removeLineItems(checkoutId, lineItem)

    if (updatedCheckout) return {...updatedCheckout}
    return null
  }
)

export const addDiscount = createAsyncThunk(
  'addDiscount',
  async ({ checkoutId, discountCode }, thunkAPI) => {
    const updatedCheckout = await shopifyClient.checkout.addDiscount(checkoutId, discountCode)

    if (updatedCheckout) return {...updatedCheckout}
    return null
  }
)

export const clearDiscounts = createAsyncThunk(
  'clearDiscounts',
  async ({ checkoutId }, thunkAPI) => {
    const updatedCheckout = await shopifyClient.checkout.removeDiscount(checkoutId)

    if (updatedCheckout) return {...updatedCheckout}
    return null
  }
)

const shopifySlice = createSlice({
  name: 'shopify',
  initialState: {
    loadingCheckout: false,
    checkoutId: null,
    checkout: null,
    collections: null
  },
  reducers: {
    handleLoadingCheckout: state => {
      state.loadingCheckout = true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initCheckout.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkoutId = (action.payload.checkoutId)
      state.checkout = (action.payload.checkout)
    })
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.collections = (action.payload)
    })
    builder.addCase(addLineItem.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkout = (action.payload)
    })
    builder.addCase(updateLineItem.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkout = (action.payload)
    })
    builder.addCase(removeLineItem.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkout = (action.payload)
    })
    builder.addCase(addDiscount.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkout = (action.payload)
    })
    builder.addCase(clearDiscounts.fulfilled, (state, action) => {
      state.loadingCheckout = false
      state.checkout = (action.payload)
    })
  }
})

export const { handleLoadingCheckout } = shopifySlice.actions

export const store = configureStore({
  reducer: shopifySlice.reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['fetchCollections/fulfilled', 'initCheckout/fulfilled'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in the state
        ignoredPaths: ['collections', 'checkout'],
      },
    }),
})