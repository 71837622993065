import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import priceFormatter from '../../helpers/priceFormatter'
import { clearDiscounts, handleLoadingCheckout } from '../../store'
import CartItem from '../CartItem'
import DiscountCode from '../DiscountCode'

import './styles.scss'

const Cart = () => {
    const [cartOpen, setCartOpen] = useState(false)
    const checkout = useSelector(state => state.checkout)
    const checkoutId = useSelector(state => state.checkoutId)
    const dispatch = useDispatch()

    const handleClear = (e) => {
        e.preventDefault()
        dispatch(handleLoadingCheckout())
        dispatch(clearDiscounts({checkoutId}))
    }

    const getDiscountAmount = () => {
        if (checkout?.lineItemsSubtotalPrice?.amount && (checkout?.subtotalPrice?.amount !== checkout?.lineItemsSubtotalPrice?.amount)) {
            const difference = (priceFormatter(checkout?.lineItemsSubtotalPrice?.amount) - priceFormatter(checkout?.subtotalPrice?.amount))

            if (difference > 0) {
                return `-$${priceFormatter(difference)}`
            }
        }

        return null
    }

    return (
        <div className='toggle-contact'>
            <div style={{ opacity: cartOpen ? '0' : '1' }} className="cartIcon">
                <i className="fas fa-shopping-bag"></i>
                <span className="cart-item-count">{checkout?.lineItems?.length || '0'}</span>
            </div>
            <div className={`contact${cartOpen ? ' open' : ''}`} onClick={() => setCartOpen(!cartOpen)}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            <div style={{transform: `translateX(${cartOpen ? 0 : '100%'})`}} className="cart-container">
                <div className='cart-header' >
                    <h2 className='cart-title'>cart.</h2>
                </div>
                <div className="cart-items-container">
                    {checkout?.lineItems && checkout?.lineItems.map((item, i) => <CartItem key={item?.id} item={item} last={i === checkout?.lineItems.length - 1}/>)}
                </div>
                <h3 className='cart-shipping-header'><strong>FREE SHIPPING</strong> ON ORDERS OVER $140</h3>
                <div className='cart-footer' >
                    <div>
                        <DiscountCode/>
                        {checkout?.discountApplications?.map(code => 
                            <span key={code.code} className='discount-code'>
                                {code.code} 
                                <span onClick={handleClear}>
                                    <i style={{fontSize: '12px', color: 'red', marginLeft: '5px'}} className="fas fa-minus-circle"></i>
                                </span>
                            </span>
                        )}
                    </div>

                    <div style={{fontSize: '1.1em', fontFamily: 'Josefin Sans', textAlign: 'right'}}>
                        {getDiscountAmount() && <p style={{marginBottom: '5px'}}>Subtotal: ${priceFormatter(checkout?.lineItemsSubtotalPrice?.amount)}</p>}
                        {getDiscountAmount() && <p style={{marginBottom: '5px'}}>Discounts: {getDiscountAmount()}</p>}
                        <p style={{fontSize: '1.2em', marginBottom: '15px'}}><strong>Total: ${priceFormatter(checkout?.subtotalPrice?.amount)}</strong></p>
                        <a className='checkout-button' href={checkout?.lineItems.length > 0 ? checkout?.webUrl : null} target="_blank" rel="noopener noreferrer">CHECKOUT</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart