import logo from './logo.png'
import canada from './canada.png'
import drip from './drip.png'
import french from './french.png'
import machine from './machine.png'
import mocha from './mocha.png'
import beans from './beans.png'

const Icons = {
    vibeNav: logo,
    canada: canada,
    'Drip': drip,
    'Espresso Machine': machine,
    'Espresso Mocha': mocha,
    'French Press': french,
    'Whole': beans
}

export default Icons