import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addDiscount, handleLoadingCheckout } from '../../store'

import './styles.scss'

const DiscountCode = () => {
    const [discountCode, setDiscountCode] = useState('')
    const checkoutId = useSelector(state => state.checkoutId)
    const checkout = useSelector(state => state.checkout)

    const dispatch = useDispatch()

    const handleDiscountChange = (e) => {
        e.preventDefault()
        setDiscountCode(e.target.value)
    }

    const handleSubmitDiscount = (e) => {
        e.preventDefault()
        if (checkout?.discountApplications?.length === 0) {
            dispatch(handleLoadingCheckout())
            dispatch(addDiscount({ checkoutId, discountCode }))
            setDiscountCode("")
        }
    }

    return (
        <form onSubmit={handleSubmitDiscount} style={{display: 'flex', marginBottom: '10px'}}>
            <input className="discount-input" type="text" placeholder='DISCOUNT CODE' onChange={handleDiscountChange} value={discountCode}/>
            <span onClick={handleSubmitDiscount} className="discount-button">GO</span>
        </form>
    )
}

export default DiscountCode