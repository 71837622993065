import React from 'react'
import { useSelector } from 'react-redux'
import Product from '../../components/Product'
import './styles.scss'
import MetaTags from 'react-meta-tags';

const Beans = () => {
    const collections = useSelector(state => state.collections)

    return (
        <div className='beans'>
            <MetaTags>
                <title>VIBE COFFEE · Damn Good Coffee</title>
                <meta name='description' content="Proud Canadian roasters of premium coffee. Enjoy our roast in Nespresso compatible aluminum capsules and frac packs."/>
                <meta property='og:title' content="VIBE COFFEE · Damn Good Coffee" />
                <meta property='og:description' content="Proud Canadian roasters of premium coffee. Enjoy our roast in Nespresso compatible aluminum capsules and frac packs."/>
            </MetaTags>
            <h2 className='beans-header'>our roasts.</h2>
            <h3 className='beans-shipping-header'><strong>FREE SHIPPING</strong> ON ORDERS OVER $140</h3>
            {collections?.map(collection => {
                return (
                    <React.Fragment key={collection?.id}>
                        {collection?.products?.length > 0 && <h3 className='beans-subheader'>{collection?.title?.toUpperCase()}</h3>}
                        {
                            collection?.products?.map((product) => {
                                return <Product coffee={product} key={product.id}/>
                            })
                        }
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default Beans