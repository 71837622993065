import React, { createRef, useState, useEffect } from 'react'
import Photos from '../../assets/photos'
import { useScrollPosition } from '../../hooks/useScrollPosition'
import { motion } from 'framer-motion'
import './styles.scss'
import ContactForm from '../../components/ContactForm'
import MetaTags from 'react-meta-tags';

const Business = (props) => {
    const aboutRef = createRef(null)
    const aboutSecondRef = createRef(null)

    const [first, setFirst] = useState('hidden')
    const [second, setSecond] = useState('hidden')

    useEffect(() => {
        setFirst('visible')
    }, [aboutRef])

    useEffect(() => {
        setSecond('visible')
    }, [aboutSecondRef])

    useScrollPosition(() => {
        const visible = window.scrollY > (aboutRef.current.offsetTop - aboutRef.current.offsetTop * 0.6)
        visible && setFirst('visible')
    })

    useScrollPosition(() => {
        const visible = window.scrollY > (aboutSecondRef.current.offsetTop - aboutSecondRef.current.clientHeight * 1.2)
        visible && setSecond('visible')
    })

    const info = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 1
            }
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
            }
        }
    }

    return (
        <div className='about'>
            <MetaTags>
                <title>VIBE Coffee for Your Business</title>
                <meta name='description' content="The VIBE Coffee foodservice program brings a range of premium roasts to your coffee bar or workplace."/>
                <meta property='og:title' content="VIBE Coffee for Your Business" />
                <meta property='og:description' content="The VIBE Coffee foodservice program brings a range of premium roasts to your coffee bar or workplace."/>
            </MetaTags>
            <div className='about-bottom' ref={aboutRef}>
                <img className='about-photo-bottom' src={Photos.business} alt='espresso'/>  
                <motion.div initial="right" animate={first} variants={info} className='about-bottom-container' style={{textAlign: 'right'}}>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                    <div className='about-text-contents' style={{color: 'white', background: 'rgba(10,8,7,0.85)'}}>
                        <h1 className='about-subtitle'>vibe coffee for your business.</h1>
                        <p className='about-text'>
                            <strong>Add Great Coffee to Your Environment</strong>
                            <br /><br />
                            At Vibe Coffee we roast the kind of coffee that keeps your customers coming back for more.
                            <br /><br />
                            If you run a specialty coffee espresso bar, boutique restaurant, boutique hotel, specialty market
                            or other enterprise, and care about the coffee you serve, Vibe Coffee is ready to help you elevate
                            your offering.
                            <br /><br />
                            The quality of our products, along with the combination of adopted old-world techniques, will
                            leave your customers feeling the <span style={{ fontStyle: 'italic' }}>vibe</span>.
                            <br /><br />
                            <strong>From Bean to Cup Using Your Private Label</strong>
                            <br /><br />
                            Our coffee frac packs are the perfect way to treat your customers and office employees with
                            premium quality, freshly roasted coffee, and can be customized for your private brand.
                        </p>
                    </div>
                    <div className="about-photo-container">

                    </div>
                    </div>
                </motion.div>
            </div>
            <div className='about-bottom' style={{background: 'white'}} ref={aboutSecondRef}>
                <img className='about-photo-bottom-white' src={Photos.contact} alt='espresso'/>
                <motion.div initial="left" animate={second} variants={info} className='about-bottom-container' style={{textAlign: 'left'}}> 
                        <div className='about-text-contents' style={{color: 'black', background: 'rgba(248,244,242,0.85)'}}>
                            <h1 className='about-subtitle' style={{textShadow: '0px 0px 0px'}}>start a conversation.</h1>
                            <ContactForm />
                        </div>
                        <div  className="about-photo-container">

                        </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Business
