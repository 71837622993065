import React from 'react'
import { Link } from 'react-router-dom'
import Icons from '../../assets/icons'
import './styles.scss'

const Header = () => {
    return (
        <div className='header' >
            <div className='menu-items'>
                <Link to='/about' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    <img src={Icons.vibeNav} className='header-logo' alt='vibe coffee'/>
                </Link>

                <Link to='/beans' className='menu-item' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    SHOP
                </Link>
                <Link to='/business' className='menu-item' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    BUSINESS
                </Link>
                <a className='menu-item' href='https://shop.vibecoffee.com/blogs/from-bean-to-cup' target='_blank' rel="noreferrer">
                    BLOG
                </a>
            </div>
        </div>
    )
}

export default Header