import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom'
import { store, fetchCollections, initCheckout, handleLoadingCheckout } from './store';

import Header from './components/Header'
import About from './pages/About'
import Business from './pages/Business'
import Beans from './pages/Beans'
import Footer from './components/Footer'
import Cart from './components/Cart'

import './App.scss';
import { MetaTags } from 'react-meta-tags';

const App = () => {
  useEffect(() => {
    store.dispatch(handleLoadingCheckout())
    store.dispatch(fetchCollections())
    store.dispatch(initCheckout())
  }, [])

  return (
    <div className="App">
      <MetaTags>
          <title>VIBE COFFEE · Damn Good Coffee</title>
          <meta name='description' content="Proud Canadian roasters of premium coffee. Enjoy our roast in Nespresso compatible aluminum capsules and frac packs."/>
          <meta property='og:title' content="VIBE COFFEE · Damn Good Coffee" />
          <meta property='og:description' content="Proud Canadian roasters of premium coffee. Enjoy our roast in Nespresso compatible aluminum capsules and frac packs."/>
      </MetaTags>
      <Cart />
      <Header />
      <Switch>
        <Route path='/about' component={() => { return <About /> }} />
        <Route path='/beans' component={() => { return <Beans collections={[]} checkoutId={''} fetchCheckout={() => ''}/> }} />
        <Route path='/business' component={() => { return <Business /> }} />
        <Route path='/' component={() => { return <Redirect to='/about' /> }} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
