import React from 'react'
import Icons from '../../assets/icons'
import './styles.scss'

const Footer = (props) => {
    return (
        <div className='footer-container'>
            <div className='footer'>
                <div className='footer-slogan'>
                    VIBE COFFEE LTD.
                    <br />
                    <br />
                    PROUDLY ROASTED 
                    <br />IN CANADA
                </div>
                <div className='footer-center'>
                    <img src={Icons.vibeNav} alt='vibe coffee' className='footer-image' />
                    <div className='footer-icons'>
                        <a target='_blank' href='https://www.instagram.com/viberoast/' rel="noreferrer">
                            <i className="fab fa-instagram" />
                        </a>
                        <a target='_blank' href='https://www.linkedin.com/company/viberoast/' rel="noreferrer">
                            <i className="fab fa-linkedin" />
                        </a>
                    </div>
                </div>
                {/* <img src={Icons.vibeNav} alt='vibe coffee' style={{height: '60%'}} /> */}
                <div className='footer-slogan'>
                    info@vibecoffee.com
                    <br />416.457.7069
                    <br />
                    <br />603 Millway Ave,
                    <br />Concord, ON, L4K 3V1
                </div>
            </div>
        </div>
    )
}

export default Footer